import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';

export const ProtectRoutes = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    useEffect(() => {
        const hasReloaded = sessionStorage.getItem('bridgeUserWasAuth');
        if (token && !hasReloaded) {
            document.cookie = `badgerToken=${token}`;
            sessionStorage.setItem('bridgeUserWasAuth', 'true');
            window.location.reload();
        }
    }, [token]);

    const authToken = document.cookie
        .split(';')
        .find((cookie) => cookie.includes('badgerToken'))
        ?.split('=')[1];
    if (!authToken || hasTokenExpired(authToken)) {
        sessionStorage.removeItem('bridgeUserWasAuth');
        const replaceUrl = window.location.href;
        window.location.replace(`https://id.badgermapping.com/?state=${replaceUrl}`);
        return null;
    }

    return <Outlet />;
};

const hasTokenExpired = (token: string) => {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime > payload.exp;
};
